var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$route.meta.title,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "数据类型" },
                              on: { change: _vm.CHsjtype },
                              model: {
                                value: _vm.sjtype,
                                callback: function ($$v) {
                                  _vm.sjtype = $$v
                                },
                                expression: "sjtype",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全量", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "增量", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.infCode"
                            ),
                            prop: "infCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "cip.dc.transformation.field.infCode"
                                ),
                              },
                              model: {
                                value: _vm.form.infCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "infCode", $$v)
                                },
                                expression: "form.infCode",
                              },
                            },
                            _vm._l(_vm.selDat, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.infDesc,
                                  value: item.infCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.fieldName"
                            ),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.fieldName"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.fieldName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fieldName", $$v)
                              },
                              expression: "form.fieldName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.targetTable"
                            ),
                            prop: "targetTable",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.targetTable"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.targetTable,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "targetTable", $$v)
                              },
                              expression: "form.targetTable",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.srcField"
                            ),
                            prop: "srcField",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.srcField"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.srcField,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "srcField", $$v)
                              },
                              expression: "form.srcField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.targetField"
                            ),
                            prop: "targetField",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.targetField"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.targetField,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "targetField", $$v)
                              },
                              expression: "form.targetField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.targetField"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "cip.dc.transformation.field.targetField"
                                ),
                              },
                              model: {
                                value: _vm.form.unitCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "unitCode", $$v)
                                },
                                expression: "form.unitCode",
                              },
                            },
                            _vm._l(_vm.selData, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.mapCode"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "cip.dc.transformation.field.mapCode"
                                ),
                              },
                              model: {
                                value: _vm.form.keycolumn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "keycolumn", $$v)
                                },
                                expression: "form.keycolumn",
                              },
                            },
                            _vm._l(_vm.selData, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.groupNo"
                            ),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.groupNo"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.groupNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupNo", $$v)
                              },
                              expression: "form.groupNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.defaultValue"
                            ),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.defaultValue"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.defaultValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "defaultValue", $$v)
                              },
                              expression: "form.defaultValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.dimension"
                            ),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.dimension"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.dimension,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dimension", $$v)
                              },
                              expression: "form.dimension",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.transformation.field.dimensionSed"
                            ),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.transformation.field.dimensionSed"
                              ),
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.dimensionSed,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dimensionSed", $$v)
                              },
                              expression: "form.dimensionSed",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }